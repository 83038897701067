import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const t = TrelloPowerUp.iframe()
    const promise = this.constructor.promise(
      properties.formData.name, properties.projectId, t, properties.index)
    // Don't use `windows/reload` to prevent horizontal scrollbar getting reset.
    this.executeCardUpdate(promise, properties, component, true)
  }

  // This gets called from `projects/create`
  static async promise(name, projectId, t, index, description) {
    let firstList = $tpu.r.lists.first()
    if (!firstList) {
      for (const [index, name] of ["Backlog", "In Progress", "Done"].entries()) {
        await $tpu.r.lists.create({ name: name, pos: index + 1 }, t)
      }

      firstList = $tpu.r.lists.first()
    }

    return $tpu.r.scopes.create(
      { name: name, desc: description },
      firstList.id,
      projectId,
      t,
      index
    )
  }
}
